<template>
  <div class="product-map">
    <div class="chart" id="map-chart" />
    <template v-if="clickEvent">
      <div class="mask" @click="onMaskClick" />
      <div class="selector" :style="selectorStyle">
        <button class="project" v-for="project in clickEvent.projects" :key="project.id" @click="onProjectClick(project.id)">
          <span class="text">{{project.name}}</span>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import { ref, computed, watchEffect, onMounted, onBeforeUnmount } from "vue";
import { useRouter } from "vue-router";
import * as echarts from "echarts";
import product from "@/compositions/product";
import productCharts from "@/charts/productCharts";

export default {
  name: "ProductMap",
  setup() {
    const router = useRouter();
    const data = product.init();
    const { projectStat } = data;
    const clickEvent = ref(null);
    const selectorStyle = computed(() => {
      const style = {};
      if (clickEvent.value) {
        if (clickEvent.value.y > 500) {
          style.bottom = `${1080 - clickEvent.value.y}px`;
        } else {
          style.top = `${clickEvent.value.y}px`;
        }
        style.left = `${clickEvent.value.x}px`;
      }
      return style;
    });

    let mapChart = null;

    onMounted(() => {
      watchEffect(() => {
        if (Object.keys(projectStat.value).length > 0) {
          if (!mapChart) {
            mapChart = echarts.init(document.getElementById("map-chart"));
          }
          productCharts.updateMapChart(mapChart, projectStat);
          mapChart.on("click", (params) => {
            if (params.seriesIndex === 0 && params.data && projectStat.value.areaDistributed) {
              let projects = [];
              for (const area of projectStat.value.areaDistributed) {
                if (area.area === params.data.name) {
                  projects = area.details;
                  break;
                }
              }
              clickEvent.value = { province: params.data.name, x: params.event.offsetX, y: params.event.offsetY, projects: projects };
            }
          });
        } else {
          if (mapChart) {
            mapChart.dispose();
            mapChart = null;
          }
        }
      });
    });

    const onMaskClick = () => {
      clickEvent.value = null;
    };

    const onProjectClick = (id) => {
      router.push({
        path: "/project", 
        query: { 
          id: id 
        }
      });
    };

    onBeforeUnmount(() => {
      if (mapChart) {
        mapChart.dispose();
        mapChart = null;
      }
    });

    return {
      clickEvent,
      selectorStyle,
      onMaskClick,
      onProjectClick
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.product-map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.selector {
  position: absolute;
  @extend %vertical;
  padding: 6px 0;
  min-width: 124px;
  max-width: 240px;
  background-color: #333333;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid var(--secondary-border-color);

  .project {
    @extend %horizontal;
    width: 100%;
    height: 34px;

    .text {
      margin: 0 20px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: var(--secondary-font-color);
      @include ellipsis();
    }

    &:hover {
      background-color: var(--secondary-background-color);
    }
  }
}
</style>
