<template>
  <div class="product-right">
    <div class="section top">
      <title-bar :icon="clockIcon" title="产品状态" />
      <div class="separator" />
      <div class="body" v-if="Object.keys(productIntroduction).length > 0">
        <div class="step-icons">
          <div :class="{item: true, running: productIntroduction.productStatus === 0, finish: productIntroduction.productStatus > 0}">
            <div class="icon">
              <img class="image" :src="discussRunningIcon" v-if="productIntroduction.productStatus === 0" />
              <img class="image" :src="discussFinishIcon" v-else />
            </div>
            <span class="text">评审</span>
          </div>
          <div :class="{item: true, running: productIntroduction.productStatus === 1, finish: productIntroduction.productStatus > 1}">
            <div class="icon">
              <img class="image" :src="raiseWaitingIcon" v-if="productIntroduction.productStatus < 1" />
              <img class="image" :src="raiseRunningIcon" v-else-if="productIntroduction.productStatus === 1" />
              <img class="image" :src="raiseFinishIcon" v-else />
            </div>
            <span class="text">募集</span>
          </div>
          <div :class="{item: true, running: productIntroduction.productStatus === 2, finish: productIntroduction.productStatus > 2}">
            <div class="icon">
              <img class="image" :src="investWaitingIcon" v-if="productIntroduction.productStatus < 2" />
              <img class="image" :src="investRunningIcon" v-else-if="productIntroduction.productStatus === 2" />
              <img class="image" :src="investFinishIcon" v-else />
            </div>
            <span class="text">投资</span>
          </div>
          <div :class="{item: true, running: productIntroduction.productStatus === 3, finish: productIntroduction.productStatus > 3}">
            <div class="icon">
              <img class="image" :src="manageWaitingIcon" v-if="productIntroduction.productStatus < 3" />
              <img class="image" :src="manageRunningIcon" v-else-if="productIntroduction.productStatus === 3" />
              <img class="image" :src="manageFinishIcon" v-else />
            </div>
            <span class="text">管理</span>
          </div>
          <div :class="{item: true, finish: productIntroduction.productStatus === 4}">
            <div class="icon">
              <img class="image" :src="exitWaitingIcon" v-if="productIntroduction.productStatus < 4" />
              <img class="image" :src="exitFinishIcon" v-else />
            </div>
            <span class="text">退出</span>
          </div>
        </div>
        <div class="step-lines">
          <div class="point" />
          <div class="line" />
          <div class="point" />
          <div class="line" />
          <div class="point" />
          <div class="line" />
          <div class="point" />
          <div class="line" />
          <div class="point" />
        </div>
        <div class="step-statuses">
          <img class="icon" :src="runningIcon" v-if="productIntroduction.productStatus === 0" />
          <img class="icon" src="/images/finish.svg" v-else />
          <img class="icon" :src="waitingIcon" v-if="productIntroduction.productStatus < 1" />
          <img class="icon" :src="runningIcon" v-else-if="productIntroduction.productStatus === 1" />
          <img class="icon" src="/images/finish.svg" v-else />
          <img class="icon" :src="waitingIcon" v-if="productIntroduction.productStatus < 2" />
          <img class="icon" :src="runningIcon" v-else-if="productIntroduction.productStatus === 2" />
          <img class="icon" src="/images/finish.svg" v-else />
          <img class="icon" :src="waitingIcon" v-if="productIntroduction.productStatus < 3" />
          <img class="icon" :src="runningIcon" v-else-if="productIntroduction.productStatus === 3" />
          <img class="icon" src="/images/finish.svg" v-else />
          <img class="icon" :src="waitingIcon" v-if="productIntroduction.productStatus < 4" />
          <img class="icon" src="/images/finish.svg" v-else />
        </div>
      </div>
      <div class="body" v-else>
        <empty />
      </div>
    </div>
    <div class="section middle">
      <title-bar :icon="listIcon" title="项目列表" />
      <div class="separator" />
      <div class="body" v-if="projectList.length > 0">
        <swiper class="swiper progress" :modules="modules" direction="vertical" :autoplay="{delay: 5000}" :slides-per-view="4">
          <swiper-slide :class="['project-progress', index % 2 === 1 ? 'odd': '']" v-for="(project, index) in projectList" :key="project.projectId">
            <div class="seq">
              <img class="bg" :src="projectSeqIcon" />
              <span class="text">{{index + 1}}</span>
            </div>
            <div class="info">
              <span class="name">{{project.projectName}}</span>
              <div class="dates">
                <template v-if="project.projectBeginDate && project.projectFinishDate">
                  <span class="date">{{project.projectBeginDate}}</span>
                  <span class="to">至</span>
                  <span class="date">{{project.projectFinishDate}}</span>
                </template>
              </div>
            </div>
            <div class="progress">
              <span :class="['text', project.projectProgress === 100 ? 'finish' : '']">{{project.projectProgress}}%</span>
              <el-progress 
                class="bar" 
                :color="project.projectProgress === 100 ? successColor : '#FFBA27'" 
                :percentage="project.projectProgress" 
                :show-text="false" 
              />
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="body" v-else>
        <empty />
      </div>
    </div>
    <div class="section bottom">
      <title-bar :icon="projectIntroductionIcon" title="项目介绍">
        <template v-slot:right>
          <button class="btn-detail" @click="onProjectClick">
            <span class="text">看板详情</span>
            <img class="icon" :src="playIcon" />
          </button>
        </template>
      </title-bar>
      <div class="separator" />
      <div class="body" v-if="projectList.length > 0">
        <swiper class="swiper" :modules="modules" :autoplay="{delay: 5000}" @slideChange="onProjectChange">
          <swiper-slide class="project" v-for="project in projectList" :key="project.projectId">
            <div class="field">
              <span class="title">项目名称</span>
              <span class="text">{{project.projectName}}</span>
            </div>
            <div class="field">
              <span class="title">建设单位</span>
              <span class="text">{{project.buildingCompany}}</span>
            </div>
            <div class="field">
              <span class="title">建设地址</span>
              <span class="text">{{project.buildingAddress}}</span>
            </div>
            <div class="field">
              <span class="title">开工时间</span>
              <span class="text">{{project.projectBeginDate}}</span>
            </div>
            <div class="field">
              <span class="title">竣工时间</span>
              <span class="text">{{project.projectFinishDate}}</span>
            </div>
            <div class="field">
              <span class="title">容积率</span>
              <span class="text">{{project.volumnRate}}</span>
            </div>
            <div class="field">
              <span class="title">绿地率</span>
              <span class="text">{{project.greenSpaceRate}}%</span>
            </div>
            <div class="field">
              <span class="title">建筑密度</span>
              <span class="text">{{project.buildingDensity}}%</span>
            </div>
            <div class="field">
              <span class="title">建筑最高层</span>
              <span class="text">{{project.buildingTop}}</span>
            </div>
            <div class="datas">
              <div class="item">
                <div class="icon">
                  <img class="image" :src="landAreaIcon" />
                </div>
                <div class="info">
                  <span class="title">用地面积</span>
                  <span class="text">{{project.landArea}}㎡</span>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img class="image" :src="countAreaIcon" />
                </div>
                <div class="info">
                  <span class="title">计容面积</span>
                  <span class="text">{{project.volumnArea}}㎡</span>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img class="image" :src="buildingAreaIcon" />
                </div>
                <div class="info">
                  <span class="title">总建筑面积</span>
                  <span class="text">{{project.totalBuildingArea}}㎡</span>
                </div>
              </div>
              <div class="item">
                <div class="icon">
                  <img class="image" :src="saleAreaIcon" />
                </div>
                <div class="info">
                  <span class="title">计容可售面积</span>
                  <span class="text">{{project.volumnSaleArea}}㎡</span>
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper>
      </div>
      <div class="body" v-else>
        <empty />
      </div>
    </div>
	</div>
</template>

<script>
import { computed }  from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import TitleBar from "@/components/common/TitleBar";
import Empty from "@/components/common/Empty";
import product from "@/compositions/product";

export default {
  name: "ProductRight",
  components: {
    Swiper,
    SwiperSlide,
    TitleBar,
    Empty
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const clockIcon = computed(() => store.getters["theme/clockIcon"]);
    const playIcon = computed(() => store.getters["theme/playIcon"]);
    const discussFinishIcon = computed(() => store.getters["theme/discussFinishIcon"]);
    const discussRunningIcon = computed(() => store.getters["theme/discussRunningIcon"]);
    const discussWaitingIcon = computed(() => store.getters["theme/discussWaitingIcon"]);
    const raiseFinishIcon = computed(() => store.getters["theme/raiseFinishIcon"]);
    const raiseRunningIcon = computed(() => store.getters["theme/raiseRunningIcon"]);
    const raiseWaitingIcon = computed(() => store.getters["theme/raiseWaitingIcon"]);
    const investFinishIcon = computed(() => store.getters["theme/investFinishIcon"]);
    const investRunningIcon = computed(() => store.getters["theme/investRunningIcon"]);
    const investWaitingIcon = computed(() => store.getters["theme/investWaitingIcon"]);
    const manageFinishIcon = computed(() => store.getters["theme/manageFinishIcon"]);
    const manageRunningIcon = computed(() => store.getters["theme/manageRunningIcon"]);
    const manageWaitingIcon = computed(() => store.getters["theme/manageWaitingIcon"]);
    const exitFinishIcon = computed(() => store.getters["theme/exitFinishIcon"]);
    const exitRunningIcon = computed(() => store.getters["theme/exitRunningIcon"]);
    const exitWaitingIcon = computed(() => store.getters["theme/exitWaitingIcon"]);
    const runningIcon = computed(() => store.getters["theme/runningIcon"]);
    const waitingIcon = computed(() => store.getters["theme/waitingIcon"]);
    const listIcon = computed(() => store.getters["theme/listIcon"]);
    const projectSeqIcon = computed(() => store.getters["theme/projectSeqIcon"]);
    const successColor = computed(() => store.getters["theme/successColor"]);
    const projectIntroductionIcon = computed(() => store.getters["theme/projectIntroductionIcon"]);
    const landAreaIcon = computed(() => store.getters["theme/landAreaIcon"]);
    const countAreaIcon = computed(() => store.getters["theme/countAreaIcon"]);
    const buildingAreaIcon = computed(() => store.getters["theme/buildingAreaIcon"]);
    const saleAreaIcon = computed(() => store.getters["theme/saleAreaIcon"]);

    const data = product.init();
    const { projectList, projectId, productIntroduction } = data;

    const onProjectChange = (event) => {
      const index = event.realIndex;
      projectId.value = projectList.value[index].projectId;
    };

    const onProjectClick = () => {
      if (!projectId.value) {
        return;
      }
      router.push({
        path: "/project", 
        query: { 
          id: projectId.value
        }
      });
    };

    return {
      clockIcon,
      playIcon,
      discussFinishIcon,
      discussRunningIcon,
      discussWaitingIcon,
      raiseFinishIcon,
      raiseRunningIcon,
      raiseWaitingIcon,
      investFinishIcon,
      investRunningIcon,
      investWaitingIcon,
      manageFinishIcon,
      manageRunningIcon,
      manageWaitingIcon,
      exitFinishIcon,
      exitRunningIcon,
      exitWaitingIcon,
      runningIcon,
      waitingIcon,
      listIcon,
      successColor,
      projectSeqIcon,
      projectIntroductionIcon,
      landAreaIcon,
      countAreaIcon,
      buildingAreaIcon,
      saleAreaIcon,
      projectList,
      productIntroduction,
      modules: [Autoplay],
      onProjectChange,
      onProjectClick
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.product-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 16px;
  width: 376px;
  background-color: var(--main-background-color);

  &.top {
    height: 185px;
  }

  &.middle {
    height: 285px;
  }

  &.bottom {
    flex: 1;
  }

  .btn-detail {
    @extend %center;
    width: 84px;
    height: 24px;
    background-color: var(--secondary-background-color);

    .text {
      font-size: 12px;
      font-family: MicrosoftYaHeiSemibold;
      color: var(--main-font-color);
    }

    .icon {
      margin-left: 4px;
      width: 16px;
      height: 16px;
    }
  }

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    .step-icons {
      @extend %horizontal;
      margin-top: 23px;
      align-self: center;

      .item {
        @extend %vertical;
        margin-right: 35px;

        .icon {
          @extend %center;
          width: 40px;
          height: 40px;
          background-color: var(--secondary-background-color);
          border-radius: 50%;
        }
        
        .text {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: var(--tip-font-color);
          line-height: 19px;
        }

        &.running {
          .icon {
            background-color: var(--main-font-color);
          }

          .text {
            color: var(--main-font-color);
          }
        }

        &.finish {
          .icon {
            background-color: var(--secondary-background-color);
          }

          .text {
            color: var(--main-font-color);
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .step-lines {
      @extend %horizontal;
      align-self: center;
      margin-top: 8px;

      .point {
        width: 6px;
        height: 6px;
        background-color: var(--main-border-color);
        border-radius: 50%;
        border: 1px solid #090D0C;
      }

      .line {
        width: 69px;
        height: 1px;
        background-color: var(--main-border-color);
      }
    }

    .step-statuses {
      @extend %horizontal;
      align-self: center;
      margin-top: 9px;

      .icon {
        margin-right: 57.5px;
        width: 20px;
        height: 20px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .swiper {
      width: 100%;
      height: 100%;

      &.progress {
        height: 246px;
      }
    }

    .project-progress {
      @extend %horizontal;
      width: 100%;
      height: 61.5px;

      .seq {
        position: relative;
        @extend %center;
        margin-left: 12px;
        width: 28px;
        height: 33px;

        .bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        .text {
          font-size: 14px;
          font-family: JetBrainsMono-Regular, JetBrainsMono;
          font-weight: 400;
          color: var(--main-font-color);
        }
      }

      .info {
        display: flex;
        flex-direction: column;

        .name {
          margin-left: 12px;
          width: 180px;
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: var(--tip-font-color);
          line-height: 16px;
          @include ellipsis();
        }

        .dates {
          @extend %horizontal;
          margin-top: 3px;
          margin-left: 12px;
          height: 18px;
        }

        .date {
          font-size: 14px;
          font-family: JetBrainsMono-Regular, JetBrainsMono;
          font-weight: 400;
          color: var(--main-font-color);
          line-height: 18px;
        }

        .to {
          margin: 0 4px;
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          color: var(--tip-font-color);
          line-height: 18px;
        }
      }

      .progress {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        .text {
          font-size: 14px;
          font-family: JetBrainsMono-Regular, JetBrainsMono;
          font-weight: 400;
          color: #FFBA27;
          line-height: 18px;

          &.finish {
            color: var(--success-color);
          }
        }

        .bar {
          margin-top: 6px;
          width: 93px;
        }
      }

      &.odd {
        background-color: var(--thirdary-background-color);
      }
    }

    .project {
      width: 100%;
      height: 273px;
    }

    .field {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8px;

      &:first-child {
        margin-top: 12px;
      }

      &:last-child {
        margin-top: 5px;
      }

      .title {
        margin-left: 16px;
        width: 70px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: var(--secondary-font-color);
        line-height: 20px;
      }

      .text {
        margin-left: 14px;
        margin-right: 8px;
        width: 268px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: var(--main-font-color);
        line-height: 20px;
        @include ellipsis(3);
      }
    }

    .datas {
      display: flex;
      flex-wrap: wrap;

      .item {
        @extend %horizontal;
        width: 188px;
        height: 72px;

        .icon {
          @extend %center;
          margin-left: 12px;
          width: 48px;
          height: 48px;
          background-color: var(--secondary-background-color);
          border-radius: 50%;

          .image {
            width: 28px;
            height: 28px;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          margin-left: 8px;

          .title {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: var(--secondary-font-color);
            line-height: 16px;
          }

          .text {
            font-size: 18px;
            font-family: JetBrainsMonoRoman-Medium, JetBrainsMonoRoman;
            font-weight: 500;
            color: var(--main-font-color);
            line-height: 23px;
          }
        }
      }
    }
  }
}
</style>
