<template>
  <div class="product-header">
    <img class="time-bg" :src="timeBgIcon" />
    <div class="separator" />
    <div class="left">
      <logo class="logo" title="产品看板" />
      <el-cascader 
        :style="{marginLeft: '16px', width: '120px'}" 
        ref="productSelector"
        :options="productTree" 
        :props="{value: 'id', label: 'name', children: 'children', expandTrigger: 'hover', checkStrictly: true}"
        v-model="selectProductId"
        @change="onProductChange"
      />
      <div :class="{status: true, ...getStatusClass()}" v-if="Object.keys(productIntroduction).length > 0">
        <div class="bg" />
        <div class="status-left">
          <img class="icon" :src="alertIcon" />
          <span class="text">产品预警</span>
        </div>
        <span class="status-text">{{getStatusText()}}</span>
      </div>
    </div>
    <div class="clock">
      <clock />
    </div>
    <commands />
	</div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import product from "@/compositions/product";
import Logo from "@/components/common/Logo";
import Clock from "@/components/common/Clock";
import Commands from "@/components/common/Commands";

export default {
  name: "ProductHeader",
  components: {
    Logo,
    Clock,
    Commands
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const timeBgIcon = computed(() => store.getters["theme/timeBgIcon"]);
    const alertIcon = computed(() => store.getters["theme/alertIcon"]);
    const changeThemeIcon = computed(() => store.getters["theme/changeThemeIcon"]);
    const productSelector = ref(null);

    const data = product.init();
    const { productTree, productIntroduction, productList, selectProductId, productId } = data;

    const onProductChange = (data) => {
      if (data[1]) {
        const projectId = Number(data[1].split("-")[1]);
        selectProductId.value = data[1];
        productSelector.value.popperVisible = false;
        router.push({
          path: "/project", 
          query: { 
            id: projectId
          }
        });
      } else {
        const value = data[0];
        if (value === 0) {
          selectProductId.value = productId.value;
          productSelector.value.popperVisible = false;
          router.replace("/general");
        } else {
          const id = Number(value.split("-")[1]);
          // selectProductId.value = value;
          // productId.value = id;
          // productSelector.value.popperVisible = false;
          router.replace({
            path: "/product", 
            query: {
              id: id
            }
          });
          setTimeout(() => router.go(0), 0);
        }
      }
    };

    const getStatusClass = () => {
      if (productIntroduction.value.warningStatus === 1) {
        return { warn: true };
      } else if (productIntroduction.value.warningStatus === 2) {
        return { danger: true };
      } else {
        return {};
      }
    };

    const getStatusText = () => {
      if (productIntroduction.value.warningStatus === 1) {
        return "预警";
      } else if (productIntroduction.value.warningStatus === 2) {
        return "出险";
      } else {
        return "正常";
      }
    };

    return {
      timeBgIcon,
      alertIcon,
      changeThemeIcon,
      productSelector,
      productTree,
      productIntroduction,
      productList,
      selectProductId,
      productId,
      onProductChange,
      getStatusClass,
      getStatusText
    };
  }
}
</script>

<style lang="scss">
.popper {
  background-color: #333333 !important;
  border: none !important;

  .el-cascader-node__label {
    color: #999999;
  }

  .el-radio__inner {
    background-color: #333333 !important;
    border: 1px solid #999999;
  }

  .el-radio__input.is-checked .el-radio__inner {
    background-color: #FF8517 !important;
    border: none;
  }

  .el-cascader-menu {
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  .el-cascader-node.is-active {
    color: #FF8517;
  }

  .el-popper__arrow::before {
    background-color: #333333 !important;
    border-color: #333333 !important;
  }
}
</style>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.product-header {
  position: relative;
  @extend %horizontal;
  justify-content: space-between;
  width: 100%;
  height: 80px;

  .time-bg {
    position: absolute;
    top: 0;
    right: 0;
    width: 509px;
    height: 80px;
  }

  .separator {
    position: absolute;
    top: 71px;
    left: 16px;
    width: 1888px;
    height: 1px;
    background-color: var(--main-border-color);
  }

  .left {
    @extend %horizontal;

    .logo {
      margin-left: 32px;
    }

    .selector {
      margin-left: 16px;
      width: 120px;
    }

    .status {
      position: relative;
      @extend %horizontal;
      justify-content: space-between;
      margin-left: 12px;
      width: 150px;
      height: 36px;

      .bg {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: var(--success-color);
        opacity: 0.2;
      }

      .status-left {
        @extend %horizontal;
        margin-left: 12px;

        .icon {
          width: 18px;
          height: 18px;
        }

        .text {
          margin-left: 4px;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: var(--main-font-color);
        }
      }

      .status-text {
        margin-right: 16px;
        font-size: 14px;
        font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
        font-weight: bold;
        color: var(--success-color);
      }

      &.warn {
        .bg {
          background-color: var(--warn-color);
        }

        .status-text {
          color: var(--warn-color);
        }
      }

      &.danger {
        .bg {
          background-color: var(--danger-color);
        }

        .status-text {
          color: var(--danger-color);
        }
      }
    }
  }

  .clock {
    display: flex;
    margin-right: 24px;
  }
}
</style>
