<template>
  <div class="product">
    <div class="inner" :style="style">
      <warning-bg :type="warningType" />
      <product-map />
      <div class="header">
        <product-header />
      </div>
      <div class="left">
        <product-left />
      </div>
      <product-middle />
      <div class="right">
        <product-right />
      </div>
      <warning-message :type="warningType" />
      <transition name="scale">
        <no-permission v-if="ready && !hasPermission" />
      </transition>
    </div>
  </div>
</template>

<script>
import { computed, onBeforeUnmount } from "vue";
import { useRoute } from "vue-router";
import user from "@/compositions/user";
import screen from "@/compositions/screen";
import product from "@/compositions/product";
import NoPermission from "@/components/common/NoPermission";
import WarningBg from "@/components/common/WarningBg";
import WarningMessage from "@/components/common/WarningMessage";
import ProductMap from "@/components/product/ProductMap";
import ProductHeader from "@/components/product/ProductHeader";
import ProductLeft from "@/components/product/ProductLeft";
import ProductMiddle from "@/components/product/ProductMiddle";
import ProductRight from "@/components/product/ProductRight";

export default {
  name: "Product",
  components: {
    NoPermission,
    WarningBg,
    WarningMessage,
    ProductMap,
    ProductHeader,
    ProductLeft,
    ProductMiddle,
    ProductRight
  },
  setup() {
    const route = useRoute();
    const { ready } = user.init();
    const { style } = screen.init();
    const { hasPermission, selectProductId, productId, productIntroduction } = product.init();
    
    const warningType = computed(() => {
      if (productIntroduction.value.warningStatus === 1) {
        return "warning";
      } else if (productIntroduction.value.warningStatus === 2) {
        return "danger";
      } else {
        return "";
      }
    });

    selectProductId.value = `product-${route.query.id}`;
    productId.value = Number(route.query.id);

    onBeforeUnmount(() => {
      product.destroy();
    });

    return {
      style,
      ready,
      hasPermission,
      warningType
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.product {
  @extend %center;
  width: 100%;
  height: 100%;
  background: var(--product-bg-color);
  background-size: cover;
}

.inner {
  position: relative;
  @extend %vertical;
  flex-shrink: 0;
  width: 1920px;
  height: 1080px;
  background: var(--product-bg) no-repeat center center;
  transform-origin: center center;
  overflow: hidden;
}

.header {
  display: flex;
  width: 100%;
  z-index: 2;
}

.left {
  position: absolute;
  top: 80px;
  left: 0;
  display: flex;
  width: 400px;
  height: 1000px;
  z-index: 2;
}

.right {
  position: absolute;
  top: 80px;
  right: 0;
  display: flex;
  width: 400px;
  height: 1000px;
  z-index: 2;
}
</style>
