import * as echarts from "echarts";

export default {
    async updateMapChart(chart, projectStat) {
        if (projectStat.value.province.length === 0) {
            return;
        }
        const map = projectStat.value.province.length > 1 ? "中华人民共和国" : projectStat.value.province[0];
        const module = await import(`@/assets/geos/${map}`);
        echarts.registerMap(map, module.default);
        const data = [];
        for (let item of projectStat.value.areaDistributed) {
            for (const detail of item.details) {
                if (!detail.lnglat) {
                    continue;
                }
                const coord = JSON.parse(detail.lnglat);
                data.push(coord);
            }
        }
        const option = {
            tooltip: {
                trigger: "item",
                formatter: (params) => {
                    return `${params.name}<br/>${params.data ? params.data.value : 0}个`;
                }
            },
            visualMap: {
                show: false,
                min: 0,
                max: 30,
                inRange: {
                    color: ["#ffdf80", "#ff8c00"]
                }
            },
            geo:  {
                type: "map",
                map: map,
                layoutCenter: map === "中华人民共和国" ? ["50%", "60%"] : ["50%", "45%"],
                layoutSize: map === "中华人民共和国" ? 1100 : 700,
                selectedMode: false,
                itemStyle: {
                    areaColor: "#707473",
                    borderColor: "#000000",
                    borderWidth: 1.5
                },
                emphasis: {
                    label: {
                        show: false
                    }
                }
            },
            series: [
                {
                    type: "map",
                    map: map,
                    layoutCenter: map === "中华人民共和国" ? ["50%", "60%"] : ["50%", "45%"],
                    layoutSize: map === "中华人民共和国" ? 1100 : 700,
                    selectedMode: false,
                    itemStyle: {
                        areaColor: "#707473",
                        borderColor: "#000000",
                        borderWidth: 1.5
                    },
                    emphasis: {
                        label: {
                            show: false
                        }
                    },
                    data: projectStat.value.areaDistributed.map((item) => {
                        return {
                            name: item.area,
                            value: item.count
                        };
                    })
                },
                {
                    type: "scatter",
                    coordinateSystem: "geo",
                    tooltip: {
                        show: false
                    },
                    symbol: "path://M189.868 24c16.27 0 29.505 13.006 29.88 29.191l0.008 0.706-0.001 11.498h667.861c22.626 0 41.013 18.165 41.378 40.712l0.006 0.684a41.405 41.405 0 0 1-9.265 26.102L792.734 289.27a11.502 11.502 0 0 0 0 14.501L919.735 460.15c14.411 17.744 11.714 43.815-6.025 58.23a41.375 41.375 0 0 1-26.094 9.267H219.755v372.56h46.593c16.506 0 29.888 13.386 29.888 29.897 0 16.276-13.002 29.514-29.182 29.889l-0.706 0.008h-152.46C97.38 960 84 946.615 84 930.103c0-16.275 13.001-29.514 29.182-29.888l0.705-0.009h46.093V53.896c0-16.51 13.382-29.896 29.888-29.896z",
                    symbolSize: 50,
                    symbolOffset: ["40%", "-50%"],
                    data: data
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateNetWorthChart(chart, productNetWorthData, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                top: "5%",
                left: "5%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "5%",
                left: "5%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    rotate: 45,
                    color: store.getters["theme/mainFontColor"]
                },
                data: productNetWorthData.value.monthList
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            series: [
                {
                    name: "单位净值",
                    type: "line",
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: productNetWorthData.value.unitNetWorthList
                },
                {
                    name: "累计净值",
                    type: "line",
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: productNetWorthData.value.totalNetWorthList
                }
            ]
        };
        chart.setOption(option, true);
    },
    updateIncomeChart(chart, productNetWorthData, store) {
        const option = {
            tooltip: {
                trigger: "item"
            },
            legend: {
                top: "5%",
                left: "5%",
                textStyle: {
                    color: store.getters["theme/secondaryFontColor"]
                }
            },
            grid: {
                bottom: "5%",
                left: "5%",
                containLabel: true
            },
            xAxis: {
                type: "category",
                axisLabel: {
                    rotate: 45,
                    color: store.getters["theme/mainFontColor"]
                },
                data: productNetWorthData.value.monthList
            },
            yAxis: {
                type: "value",
                axisLabel: {
                    color: store.getters["theme/mainFontColor"]
                },
                splitLine: {
                    lineStyle: {
                        type: "dashed",
                        color: store.getters["theme/secondaryBorderColor"]
                    }
                }
            },
            series: [
                {
                    name: "七日年化(万元)",
                    type: "line",
                    itemStyle: {
                        color: "#F74B0C"
                    },
                    data: productNetWorthData.value.weekAnnualizedRateList
                },
                {
                    name: "万份收益(万元)",
                    type: "line",
                    itemStyle: {
                        color: "#28F3C2"
                    },
                    data: productNetWorthData.value.millionIncomeList
                }
            ]
        };
        chart.setOption(option, true);
    }
}
