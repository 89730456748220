<template>
  <div class="warning-message" v-if="type === 'warning'">
    <div class="line-left" />
    <div class="line-right" />
    <img class="icon" src="/images/warning.svg" />
    <span class="text">项目预警</span>
  </div>
  <div class="warning-message danger" v-else-if="type === 'danger'">
    <div class="line-left" />
    <div class="line-right" />
    <img class="icon" src="/images/danger.svg" />
    <span class="text">项目出险</span>
  </div>
</template>

<script>
export default {
  name: "WarningMessage",
  props: ["type"]
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

@keyframes fade-out {
	0% { opacity: 1; }
  100% { 
    opacity: 0;
    visibility: hidden;
  }
}

.warning-message {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  @extend %center;
  margin: auto;
  width: 320px;
  height: 80px;
  background: linear-gradient(90deg, rgba(255, 186, 39, 0) 0%, rgba(255, 186, 39, 0.3) 51%, rgba(255, 186, 39, 0) 100%);
  border: 1px solid rgba(255, 186, 39, 0.2);
  animation: fade-out 0.5s;
  animation-delay: 5s;
  animation-fill-mode: forwards;

  .line-left {
    position: absolute;
    top: 0;
    left: 0;
    width: 2px;
    height: 100%;
    background-color: #FFBA27;
  }

  .line-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100%;
    background-color: #FFBA27;
  }

  .icon {
    margin-right: 4px;
    width: 32px;
    height: 32px;
  }

  .text {
    font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
    font-size: 24px;
    font-weight: bold;
    color: #FFBA27;
  }

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/images/warning-bg.png);
    content: "";
  }

  &.danger {
    background: linear-gradient(90deg, rgba(239, 42, 42, 0) 0%, rgba(239, 42, 42, 0.3) 50%, rgba(239, 42, 42, 0) 100%);
    border: 1px solid rgba(239, 42, 42, 0.2);

    .line-left {
      background-color: #EF2A2A;
    }

    .line-right {
      background-color: #EF2A2A;
    }

    .text {
      color: #EF2A2A;
    }

    &::before {
      background: url(/images/danger-bg.png);
    }
  }
}
</style>

