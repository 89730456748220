<template>
  <div class="top">
    <div class="item blue">
      <div class="line" />
      <template v-if="Object.keys(projectStat).length > 0">
        <span class="title">项目总数</span>
        <div class="value">
          <span class="num">{{projectStat.projectCount}}</span>
          <span class="unit">个</span>
        </div>
      </template>
      <empty v-else />
    </div>
    <div class="item orange">
      <div class="line" />
      <template v-if="Object.keys(projectStat).length > 0">
        <span class="title">分布省份</span>
        <div class="value">
          <span class="num">{{provinces}}</span>
        </div>
      </template>
      <empty v-else />
    </div>
    <div class="item green">
      <div class="line" />
      <template v-if="Object.keys(projectStat).length > 0">
        <span class="title">项目规模</span>
        <div class="value">
          <span class="num">{{projectStat.projectScale}}</span>
          <span class="unit">亿</span>
        </div>
      </template>
      <empty v-else />
    </div>
  </div>
  <div class="bottom">
    <div class="separator" />
    <div class="notice" v-if="announcementList.length > 0">
      <img class="icon" src="/images/notice.svg" />
      <marquee class="content" :pauseOnHover="true">
        <div class="item" v-for="(announcement, index) in announcementList" :key="index">
          <span class="text">【{{announcement.createTime}} {{announcement.productName}}】{{announcement.content}}</span>
        </div>
      </marquee>
    </div>
    <swiper class="swiper" :modules="modules" :autoplay="{delay: 3000, disableOnInteraction: false}" :slides-per-view="4.33">
      <swiper-slide class="swiper-slide" v-for="(picture, index) in pictures" :key="index">
        <img class="picture" :src="picture" />
      </swiper-slide>
      <template v-if="pictures.length < 5">
        <swiper-slide class="swiper-slide" v-for="index in 5 - pictures.length" :key="`placeholder-${index}`">
          <empty class="picture" />
        </swiper-slide>
      </template>
    </swiper>
  </div>
</template>

<script>
import { computed } from "vue";
import { Autoplay  } from "swiper";
import Marquee from "vue3-marquee";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import Empty from "@/components/common/Empty";
import product from "@/compositions/product";

export default {
  name: "ProductMiddle",
  components: {
    Marquee,
    Swiper,
    SwiperSlide,
    Empty
  },
  setup() {
    const data = product.init();
    const { projectStat, announcementList, project } = data;

    const provinces = computed(() => {
      if (!projectStat.value.province) {
        return "";
      } else if (projectStat.value.province.length === 1) {
        return projectStat.value.province[0];
      } else {
        return projectStat.value.province.length;
      }
    });

    const pictures = computed(() => {
      return project.value ? project.value.pictures : [];
    });

    return {
      projectStat,
      announcementList,
      provinces,
      pictures,
      modules: [Autoplay]
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.top {
  position: absolute;
  top: 88px;
  left: 600px;
  display: flex;

  .item {
    position: relative;
    @extend %center;
    flex-direction: column;
    margin: 0 4px;
    width: 235px;
    height: 120px;
    background-color: var(--main-background-color);

    .line {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 2px;
    }

    .title {
      font-size: 16px;
      font-family: MicrosoftYaHei;
      color: var(--main-font-color);
      line-height: 21px;
    }

    .value {
      display: flex;
      margin-top: 4px;

      .num {
        font-size: 36px;
        font-family: JetBrainsMonoRoman-Bold, JetBrainsMonoRoman;
        font-weight: bold;
        line-height: 48px;
      }

      .unit {
        margin-top: 20px;
        margin-left: 4px;
        font-size: 16px;
        font-family: MicrosoftYaHei;
        color: var(--secondary-font-color);
        line-height: 21px;
      }
    }

    &.blue {
      .line {
        background-color: #00A3FF;
      }

      .value {
        .num {
          color: #00A3FF;
        }
      }
    }

    &.orange {
      .line {
        background-color: var(--brand-color);
      }

      .value {
        .num {
          color: var(--brand-color);
        }
      }
    }

    &.green {
      .line {
        background-color: #00FFC2;
      }

      .value {
        .num {
          color: #00FFC2;
        }
      }
    }
  }
}

.bottom {
  position: absolute;
  bottom: 16px;
  left: 400px;
  display: flex;
  flex-direction: column;
  width: 1104px;

  .notice {
    @extend %horizontal;
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg, rgba(255, 186, 39, 0) 0%, rgba(255, 186, 39, 0.4) 12%, rgba(255, 186, 39, 0.8) 51%, rgba(255, 186, 39, 0.4) 85%, rgba(255, 186, 39, 0) 100%);

    .icon {
      margin: 12px;
      width: 24px;
      height: 24px;
    }

    .content {
      flex: 1;
      min-width: 0;
      height: 100%;

      .item {
        @extend %horizontal;
        height: 40px;

        .text {
          margin: 0 16px;
          font-size: 16px;
          font-family: MicrosoftYaHeiSemibold;
          color: var(--main-font-color);
        }
      }
    }
  }

  .swiper {
    width: 100%;
    height: 236px;

    .swiper-slide {
      width: 240px;
      height: 236px;

      &:first-child {
        margin-left: 16px;
      }

      &:last-child {
        margin-right: 16px;
      }

      .picture {
        margin-top: 32px;
        width: 240px;
        height: 180px;
        object-fit: cover;
      }
    }
  }
}
</style>
