<template>
  <div class="warning" v-if="type === 'warning'" />
   <div class="warning danger" v-else-if="type === 'danger'" />
  <!-- <img class="warning" src="/images/warning.png" v-if="type === 'warning'" />
  <img class="warning" src="/images/danger.png" v-else-if="type === 'danger'" /> -->
</template>

<script>
export default {
  name: "WarningBg",
  props: ["type"]
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

@keyframes breathe {
	0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
}

.warning {
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(255, 186, 39, 0.30) 0%, rgba(255, 186, 39, 0.00) 10%, rgba(255, 186, 39, 0.00) 90%, rgba(255, 186, 39, 0.30) 100%);

  &.danger {
    background: linear-gradient(90deg, rgba(239, 42, 42, 0.30) 0%, rgba(239, 42, 42, 0.00) 10%, rgba(239, 42, 42, 0.00) 90%, rgba(239, 42, 42, 0.30) 100%);
  }
}

.warning::after, .warning::before {
  position: absolute;
  top: 0;
  width: 16px;
  height: 100%;
  background: url(/images/warning-line.png);
  animation: warning 1s linear infinite;
  content: "";
}

.warning::after {
  left: 0;
}

.warning::before {
  right: 0;
}

@keyframes warning {
  to {
    background-position: 0 -29px;
  }
}
</style>

