<template>
  <div class="product-left">
    <div class="section top">
      <title-bar :icon="productInfoIcon" title="产品简介">
        <template v-slot:right>
          <button class="btn-detail" @click="onProductDocumentClick">
            <span class="text">产品文档</span>
            <img class="icon" :src="playIcon" />
          </button>
        </template>
      </title-bar>
      <div class="separator" />
      <div class="body" v-if="Object.keys(productIntroduction).length > 0">
        <div class="field">
          <span class="title">产品名称</span>
          <span class="text">{{productIntroduction.productName}}</span>
        </div>
        <div class="field">
          <span class="title">产品备案号</span>
          <span class="text">{{productIntroduction.recordNo}}</span>
        </div>
        <div class="field">
          <span class="title">托管机构</span>
          <span class="text">{{productIntroduction.custodian}}</span>
        </div>
        <div class="field">
          <span class="title">成立日期</span>
          <span class="text">{{productIntroduction.foundDate}}</span>
        </div>
        <div class="field">
          <span class="title">被投资企业名称</span>
          <span class="text">{{productIntroduction.fundInvestedCompany}}</span>
        </div>
      </div>
      <div class="body" v-else>
        <empty />
      </div>
    </div>
    <div class="section middle">
      <title-bar :icon="productStatusIcon" title="产品概况" />
      <div class="separator" />
      <div class="body" v-if="Object.keys(productIntroduction).length > 0">
        <div class="project-name">
          <img class="icon" :src="folderIcon" />
          <div class="info">
            <span class="title">投资项目名称</span>
            <span class="text">{{productIntroduction.projectName}}</span>
          </div>
        </div>
        <div class="rates">
          <div class="item">
            <el-progress :width="67" type="circle" :percentage="productIntroduction.remainingPeriodRate" :color="brandColor" />
            <div class="info">
              <div class="data">
                <span class="title">存续期限</span>
                <div class="value">
                  <span class="num">{{productIntroduction.renewalPeriod}}</span>
                  <span class="unit">年</span>
                </div>
              </div>
               <div class="data">
                <span class="title">剩余期限</span>
                <div class="value">
                  <span class="num">{{productIntroduction.remainingPeriod}}</span>
                  <span class="unit">年</span>
                </div>
              </div>
            </div>
          </div>
          <div class="item">
            <el-progress :width="67" type="circle" :percentage="productIntroduction.renewalScaleRate" :color="brandColor" />
            <div class="info">
              <div class="data">
                <span class="title">合同规模</span>
                <div class="value">
                  <span class="num">{{productIntroduction.contractScale}}</span>
                  <span class="unit">亿</span>
                </div>
              </div>
               <div class="data">
                <span class="title">续存规模</span>
                <div class="value">
                  <span class="num">{{productIntroduction.renewalScale}}</span>
                  <span class="unit">亿</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="datas">
          <div class="item">
            <div class="icon">
              <img class="image" :src="riskIcon" />
            </div>
            <div class="info">
              <span class="title">风险评级</span>
              <div class="value">
                <span class="num">{{productIntroduction.riskLevel}}</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img class="image" :src="projectCountIcon" />
            </div>
            <div class="info">
              <span class="title">投资项目数</span>
              <div class="value">
                <span class="num">{{productIntroduction.projectCount}}</span>
                <span class="unit">个</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img class="image" :src="returnMoneyIcon" />
            </div>
            <div class="info">
              <span class="title">安星待回收跟款项</span>
              <div class="value">
                <span class="num">{{productIntroduction.awaitCollectPayment ? productIntroduction.awaitCollectPayment : '--'}}</span>
                <span class="unit">万</span>
              </div>
            </div>
          </div>
          <div class="item">
            <div class="icon">
              <img class="image" :src="incomeIcon" />
            </div>
            <div class="info">
              <span class="title">安星累计实现收益</span>
              <div class="value">
                <span class="num">{{productIntroduction.totalImplIncome ? productIntroduction.totalImplIncome : '--'}}</span>
                <span class="unit">万</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="body" v-else>
        <empty />
      </div>
    </div>
    <swiper class="section bottom" :modules="modules" :autoplay="true" :touchStartPreventDefault="false">
      <swiper-slide class="item">
        <title-bar :icon="productIncomeIcon" title="产品净值" />
        <div class="separator" />
        <div class="body" id="net-worth-chart" v-if="Object.keys(productNetWorthData).length > 0" />
        <div class="body" v-else>
          <empty />
        </div>
      </swiper-slide>
      <swiper-slide class="item">
        <title-bar :icon="productIncomeIcon" title="产品收益" />
        <div class="separator" />
        <div class="body" id="income-chart" v-if="Object.keys(productNetWorthData).length > 0" />
        <div class="body" v-else>
          <empty />
        </div>
      </swiper-slide>
    </swiper>
	</div>
</template>

<script>
import { computed, watchEffect, onMounted, onBeforeUnmount, nextTick } from "vue";
import { useStore } from "vuex";
import * as echarts from "echarts";
import { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import TitleBar from "@/components/common/TitleBar";
import Empty from "@/components/common/Empty";
import product from "@/compositions/product";
import productCharts from "@/charts/productCharts";

export default {
  name: "ProductLeft",
  components: {
    Swiper,
    SwiperSlide,
    TitleBar,
    Empty
  },
  setup() {
    const store = useStore();
    const themeName = computed(() => store.state.theme.name);
    const productInfoIcon = computed(() => store.getters["theme/productInfoIcon"]);
    const playIcon = computed(() => store.getters["theme/playIcon"]);
    const productStatusIcon = computed(() => store.getters["theme/productStatusIcon"]);
    const productIncomeIcon = computed(() => store.getters["theme/productIncomeIcon"]);
    const folderIcon = computed(() => store.getters["theme/folderIcon"]);
    const brandColor = computed(() => store.getters["theme/brandColor"]);
    const riskIcon = computed(() => store.getters["theme/riskIcon"]);
    const projectCountIcon = computed(() => store.getters["theme/projectCountIcon"]);
    const returnMoneyIcon = computed(() => store.getters["theme/returnMoneyIcon"]);
    const incomeIcon = computed(() => store.getters["theme/incomeIcon"]);

    const data = product.init();
    const { productIntroduction, productOverview, productNetWorthData } = data;

    let netWorthChart = null;
    let incomeChart = null;

    onMounted(() => {
      watchEffect(() => {
        if (themeName.value && Object.keys(productNetWorthData.value).length > 0) {
          nextTick(() => {
            if (!netWorthChart) {
              netWorthChart = echarts.init(document.getElementById("net-worth-chart"));
            }
            if (!incomeChart) {
              incomeChart = echarts.init(document.getElementById("income-chart"));
            }
            productCharts.updateNetWorthChart(netWorthChart, productNetWorthData, store);
            productCharts.updateIncomeChart(incomeChart, productNetWorthData, store);
          });
        } else {
          if (netWorthChart) {
            netWorthChart.dispose();
            netWorthChart = null;
          }
          if (incomeChart) {
            incomeChart.dispose();
            incomeChart = null;
          }
        }
      });
    });

    const onProductDocumentClick = () => {
      
    };

    onBeforeUnmount(() => {
      if (netWorthChart) {
        netWorthChart.dispose();
        netWorthChart = null;
      }
      if (incomeChart) {
        incomeChart.dispose();
        incomeChart = null;
      }
    });

    return {
      productInfoIcon,
      playIcon,
      productStatusIcon,
      productIncomeIcon,
      folderIcon,
      brandColor,
      riskIcon,
      projectCountIcon,
      returnMoneyIcon,
      incomeIcon,
      productIntroduction,
      productNetWorthData,
      productOverview,
      modules: [Autoplay],
      onProductDocumentClick
    };
  }
}
</script>

<style lang="scss" scoped>
@import "@/styles/main.scss";

.product-left {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  margin-left: 16px;
  width: 376px;
  background-color: var(--main-background-color);

  &.top {
    height: 285px;
  }

  &.middle {
    height: 385px;
  }

  &.bottom {
    flex: 1;

    .item {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }

  .btn-detail {
    @extend %center;
    width: 84px;
    height: 24px;
    background-color: var(--secondary-background-color);

    .text {
      font-size: 12px;
      font-family: MicrosoftYaHeiSemibold;
      color: var(--main-font-color);
    }

    .icon {
      margin-left: 4px;
      width: 16px;
      height: 16px;
    }
  }

  .body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex: 1;

    .field {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;

      &:first-child {
        margin-top: 16px;
      }

      .title {
        margin-left: 16px;
        width: 70px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: var(--secondary-font-color);
        line-height: 20px;
      }

      .text {
        margin-left: 14px;
        margin-right: 8px;
        width: 268px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: var(--main-font-color);
        line-height: 20px;
        @include ellipsis(3);
      }
    }

    .project-name {
      @extend %horizontal;
      align-self: center;
      width: 352px;
      height: 48px;
      background-color: var(--thirdary-background-color);

      .icon {
        margin-left: 12px;
        width: 24px;
        height: 24px;
      }

      .info {
        display: flex;
        flex-direction: column;
        margin-left: 12px;

        .title {
          font-size: 12px;
          font-family: MicrosoftYaHei;
          color: var(--tip-font-color);
          line-height: 16px;
        }

        .text {
          font-size: 16px;
          font-family: MicrosoftYaHei;
          color: var(--main-font-color);
          line-height: 21px;
        }
      }
    }

    .rates {
      display: flex;
      margin-top: 19px;

      .item {
        @extend %horizontal;
        margin-left: 23px;
        margin-right: 26px;

        .rate {
          width: 67px;
          height: 67px;
        }

        .info {
          display: flex;
          flex-direction: column;
          margin-left: 10px;

          .data {
            display: flex;
            flex-direction: column;

            .title {
              font-size: 12px;
              font-family: MicrosoftYaHei;
              color: var(--secondary-font-color);
              line-height: 16px;
            }

            .value {
              display: flex;

              .num {
                font-size: 20px;
                font-family: JetBrainsMonoRoman-Medium, JetBrainsMonoRoman;
                font-weight: 500;
                color: var(--brand-color);
                line-height: 26px;
              }

              .unit {
                margin-top: 7px;
                margin-left: 2px;
                font-size: 12px;
                font-family: MicrosoftYaHei;
                color: var(--brand-color);
                opacity: 0.6;
                line-height: 16px;
              }
            }

            &:first-child {
              margin-bottom: 16px;
            }
          }
        }
      }
    }

    .datas {
      display: flex;
      flex-wrap: wrap;
      margin: 23px 12px 0 12px;

      .item {
        @extend %horizontal;
        width: 176px;
        height: 69px;

        .icon {
          @extend %center;
          margin-left: 20px;
          width: 48px;
          height: 48px;
          background-color: var(--secondary-background-color);
          border-radius: 50%;

          .image {
            width: 24px;
            height: 24px;
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          margin-left: 20px;

          .title {
            font-size: 12px;
            font-family: MicrosoftYaHei;
            color: var(--secondary-font-color);
            line-height: 16px;
          }

          .value {
            display: flex;

            .num {
              font-size: 20px;
              font-family: JetBrainsMonoRoman-Medium, JetBrainsMonoRoman;
              font-weight: 500;
              color: var(--brand-color);
              line-height: 26px;
            }

            .unit {
              margin-top: 7px;
              margin-left: 2px;
              font-size: 12px;
              font-family: MicrosoftYaHei;
              color: var(--brand-color);
              opacity: 0.6;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}
</style>
